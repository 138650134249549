import { Dispatch } from '@reduxjs/toolkit'
import {
  APP_CONFIGURATION_EVENT_DETAIL,
  APP_THEME_CONFIGURATION,
  APP_UPDATE_IS_MAINTENANCE,
  APP_UPDATE_USE_SOCKET,
  GET_GAME_MANAGEMENT_COMMON_DATA,
  GET_GAME_MANAGEMENT_COMMON_DATA_PAGE_LOADING,
  GET_GAME_MANAGEMENT_DATA_THIRD_PARTY_PAGE_LOADING,
  MARKET_LOAD,
  SITE_LOADER,
} from '../type'
import {
  DOMAIN_LOADER,
  HEADERS,
  PROD_BASE_URL,
  STAGE_BASE_URL,
  URLType,
  makeGetHttpCallAsync,
} from '../../api'
import { getRootDomain, getThemeAssetUrl } from '../../utils'
import { UPDATE_SELECTED_MARKET } from './GameManagementCommonAction'
import { GET_COMMON_THEME } from '../reducers/appCommonThemeReducers'
import { ADDDED_DOMAIN } from '../../api/response/domain'
import {
  createHashMap,
  GET_ONGOING_MATCHES,
  GET_SCHEDULES_MATCHES,
  GET_TOURNAMENT,
  getFilteredEventsByUpcomingAndOnGoing,
} from './matchesAction/SchedulesMatchesAction'
import { GET_HIGHTLIGHT_MATCH } from './userAction/highlightsAction'
import { SAVE_FASTEST_ODD } from './userAction/fastestOddAction'
import { batch } from 'react-redux'
import { getEventMap } from '../../utils/internalUtils'
import {
  SAVE_ONTIME_STATUS_MARKETIDS,
  SAVE_STATUS_MARKETIDS,
} from './marketStatusAction'

export const DOMAIN_UPDATE = '@DOMAIN/UPDATE_DOMAIN'

const makeInitialCalls = async (dispatch: Dispatch, domainData: any) => {
  try {
    const [
      socketRes,
      maintenanceRes,
      responseGameManagement,
      homeData,
      appWholeConfiguration,
    ]: any = await Promise.all([
      makeGetHttpCallAsync(
        dispatch,
        `api/common/useSocket`,
        '',
        URLType.master,
        '',
        false,
        '',
      ),
      makeGetHttpCallAsync(
        dispatch,
        `api/common/maintenance`,
        '',
        URLType.master,
        '',
        false,
        '',
      ),
      makeGetHttpCallAsync(
        dispatch,
        `api/common/game/market/group`,
        '',
        URLType.master,
        false,
        false,
        '',
      ),
      makeGetHttpCallAsync(
        dispatch,
        `api/common/home`,
        '',
        URLType.master,
        false,
        false,
        '',
      ),
      makeGetHttpCallAsync(
        dispatch,
        `api/common/wholeAppConfiguration`,
        `whiteLabelId=${domainData.whitelabelId}`,
        domainData.type,
        false,
        false,
        'application/json',
        true,
      ),
    ])

    return {
      socketRes,
      maintenanceRes,
      responseGameManagement,
      homeData,
      appWholeConfiguration,
    }
  } catch (error) {
    console.error('An error occurred:', error)
    throw error
  }
}

export const domainUpdate = () => {
  return async (dispatch: Dispatch) => {
    const rootUrl = getRootDomain()
    const domainUrl =
      rootUrl === 'localhost'
        ? (import.meta.env.VITE_ENV as string) === 'PRODUCTION'
          ? PROD_BASE_URL
          : STAGE_BASE_URL
        : rootUrl

    try {
      setTimeout(() => {
        try {
          makeGetHttpCallAsync(
            dispatch,
            `api/common/game/tournament`,
            '',
            URLType.master,
            '',
            false,
            '',
          )
            .then((response: any) => {
              let resData: any = createHashMap(response.docs)
              dispatch({
                type: GET_TOURNAMENT,
                payload: resData,
              })
            })
            .catch(() => {
              dispatch({
                type: GET_TOURNAMENT,
                payload: [],
              })
            })
        } catch (err: any) {
          dispatch({
            type: GET_TOURNAMENT,
            payload: [],
          })
        }
      }, 0)

      let domainUpdateRes = ADDDED_DOMAIN[domainUrl]
      const results = await makeInitialCalls(dispatch, domainUpdateRes)
      let socketRes = results.socketRes
      let maintenanceRes = results.maintenanceRes
      let responseGameManagement = results.responseGameManagement
      let homeData = results.homeData.docs
      let appWholeConfiguration = results.appWholeConfiguration

      localStorage.setItem(HEADERS.domain, JSON.stringify(domainUpdateRes))
      let { appConfigruation, globalAppConfiguration } =
        appWholeConfiguration.docs

      dispatch({
        type: APP_UPDATE_USE_SOCKET,
        payload: socketRes?.docs?.socket,
      })

      dispatch({
        type: APP_UPDATE_IS_MAINTENANCE,
        payload: maintenanceRes?.docs?.maintenanceMode,
      })

      dispatch({
        type: DOMAIN_UPDATE,
        data: domainUpdateRes,
      })

      dispatch({
        type: APP_THEME_CONFIGURATION,
        payload: appConfigruation,
      })

      dispatch({
        type: GET_COMMON_THEME,
        data: globalAppConfiguration,
      })

      // TODO: TITLE COMMENT
      // document.title =
      //   appConfigruation.logoText + ' | Most Trusted Sports & Casino Platform'
      const favicon: any = document.getElementById('favicon')
      if (favicon) {
        favicon.href =
          appConfigruation.logo && appConfigruation.logoSmallUrl
            ? appConfigruation.logoSmallUrl.startsWith('https://') ? appConfigruation.logoSmallUrl : getThemeAssetUrl(appConfigruation.logoSmallUrl)
            : 'https://assets.future9.com/asset/generic/loader.svg'
      }

      let gameData: any = null

      if (responseGameManagement.docs) {
        gameData = responseGameManagement.docs

        dispatch({
          type: GET_GAME_MANAGEMENT_COMMON_DATA,
          loading: false,
          data: gameData,
        })

        setTimeout(() => {
          let eventIdMap = getEventMap(gameData)
          dispatch({
            type: APP_CONFIGURATION_EVENT_DETAIL,
            payload: eventIdMap,
          })
        }, 0)

        dispatch({
          type: MARKET_LOAD,
          payload: false,
        })
      }

      if (homeData && gameData) {
        let { fastestOdd, highlight, marketIds, ongoing, upcoming } = homeData

        domainUpdateRes = ADDDED_DOMAIN[domainUrl]

        batch(() => {
          dispatch({
            type: SITE_LOADER,
            payload: DOMAIN_LOADER,
          })

          dispatch({
            type: UPDATE_SELECTED_MARKET,
            loading: false,
            data: marketIds,
          })

          dispatch({
            type: GET_HIGHTLIGHT_MATCH,
            eventIds: highlight,
          })

          dispatch({
            type: SAVE_FASTEST_ODD,
            payload: fastestOdd,
          })

          dispatch({
            type: GET_SCHEDULES_MATCHES,
            payload: upcoming,
          })

          dispatch({
            type: GET_ONGOING_MATCHES,
            payload: ongoing,
          })

          setTimeout(() => {
            makeGetHttpCallAsync(
              dispatch,
              `api/common/game/tournament`,
              '',
              URLType.master,
              false,
              false,
              'application/json',
            )
              .then((res: any) => {
                let tournament = createHashMap(res.docs)
                let response = getFilteredEventsByUpcomingAndOnGoing(
                  tournament,
                  ongoing,
                  upcoming,
                  gameData,
                )

                let eventOngoingDetail = response?.ongoing
                let eventUpCommingDetail = response?.upComming
                let marketIds = response?.marketIds
                let marketIdsUpcommingLocal = response?.marketIdsUpcomming

                dispatch({
                  type: SAVE_STATUS_MARKETIDS,
                  data: marketIds,
                })

                dispatch({
                  type: SAVE_ONTIME_STATUS_MARKETIDS,
                  data: marketIdsUpcommingLocal,
                })

                dispatch({
                  type: GET_ONGOING_MATCHES,
                  payload: eventOngoingDetail,
                })

                dispatch({
                  type: GET_SCHEDULES_MATCHES,
                  payload: eventUpCommingDetail,
                })
              })
              .catch(() => {
                let response = getFilteredEventsByUpcomingAndOnGoing(
                  new Map(),
                  ongoing,
                  upcoming,
                  gameData,
                )

                let eventOngoingDetail = response?.ongoing
                let eventUpCommingDetail = response?.upComming
                let marketIds = response?.marketIds
                let marketIdsUpcommingLocal = response?.marketIdsUpcomming

                dispatch({
                  type: SAVE_STATUS_MARKETIDS,
                  data: marketIds,
                })

                dispatch({
                  type: SAVE_ONTIME_STATUS_MARKETIDS,
                  data: marketIdsUpcommingLocal,
                })

                dispatch({
                  type: GET_ONGOING_MATCHES,
                  payload: eventOngoingDetail,
                })

                dispatch({
                  type: GET_SCHEDULES_MATCHES,
                  payload: eventUpCommingDetail,
                })
              })
          }, 0)
        })
      } else {
        dispatch({
          type: SITE_LOADER,
          payload: DOMAIN_LOADER,
        })
        dispatch({
          type: MARKET_LOAD,
          payload: false,
        })
      }
    } catch (error) {
      dispatch({
        type: SITE_LOADER,
        payload: DOMAIN_LOADER,
      })
      dispatch({
        type: MARKET_LOAD,
        payload: false,
      })
      dispatch({
        type: GET_GAME_MANAGEMENT_DATA_THIRD_PARTY_PAGE_LOADING,
        loading: false,
        page: { totalPage: 1 },
      })
      dispatch({
        type: GET_GAME_MANAGEMENT_COMMON_DATA_PAGE_LOADING,
        loading: false,
        page: { totalPage: 1 },
      })
    }
  }
}
