import React, { useEffect, useState } from 'react'
import './style.css'
import { Telegram, WhatsApp } from '@mui/icons-material'

const StickyIcons: React.FC<any> = (props: {
  whatsappLink: string
  telegramLink: string
}) => {
  const [isBouncing, setIsBouncing] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setIsBouncing(true)
      setTimeout(() => setIsBouncing(false), 1000)
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <div
        className={`sticky-icons-whatsapp icon-container ${isBouncing ? 'bounce' : ''}`}
      >
        {props.whatsappLink && (
          <a
            aria-label='whatsapp link'
            href={
              props.whatsappLink && props.whatsappLink.startsWith('http')
                ? props.whatsappLink
                : `https://wa.me/${props.whatsappLink}`
            }
            target='_blank'
            rel='noopener noreferrer'
            className='cursor-pointer icon whatsapp'
          >
            <WhatsApp style={{ fontSize: '2rem' }} />
          </a>
        )}
      </div>
      <div
        className={`sticky-icons-telegram icon-container ${isBouncing ? 'bounce' : ''}`}
      >
        {props.telegramLink && (
          <a
            aria-label='telegram link'
            href={
              props.telegramLink && props.telegramLink.startsWith('http')
                ? props.telegramLink
                : `https://t.me/${props.telegramLink}`
            }
            target='_blank'
            rel='noopener noreferrer'
            className='cursor-pointer icon telegram'
          >
            <Telegram style={{ fontSize: '2rem' }} />
          </a>
        )}
      </div>
    </>
  )
}

export default StickyIcons
