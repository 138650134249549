import ConnectedLanding from './pages/landing/ConnectedLanding'
import ConnectedGame from './pages/Game/ConnectedGame'
import 'react-toastify/dist/ReactToastify.css'
import { lazy } from 'react'
import NewConnectedEvents from './pages/admin/events/NewConnectedEvents'
import ConnectedLiveCansino from './pages/liveCasinoPage/live-Casino/ConnectedLiveCansino'
import ConnectedLotteryCansino from './pages/liveCasinoPage/lottery/ConnectedLotteryCansino'
import ConnectedVirtualCansino from './pages/liveCasinoPage/virtual-casino/ConnectedVirtualCansino'
import ConnectedCrashGame from './pages/liveCasinoPage/crash-game/ConnectedCrashGame'
import ConnectedSlotsCansino from './pages/liveCasinoPage/slots/ConnectedSlotsCansino'

const BlogPage = lazy(() => import('./pages/content/blogPage/BlogPage'))
const BlogDetailPage = lazy(
  () => import('./pages/content/blogPage/BlogDetailPage'),
)
const PromotionsPage = lazy(
  () => import('./pages/content/promotionsPage/PromotionsPage'),
)
const PromotionsDetailPage = lazy(
  () => import('./pages/content/promotionsPage/PromotionsDetailPage'),
)

const RulesAndRegulation = lazy(
  () => import('./pages/pageContent/RulesAndRegulation'),
)
const TermsAndConditionPage = lazy(
  () => import('./pages/pageContent/TermsAndConditionPage'),
)
const ResponsibleGambling = lazy(
  () => import('./pages/pageContent/ResponsibleGambling'),
)
const PrivacyPolicyPage = lazy(
  () => import('./pages/pageContent/PrivacyPolicyPage'),
)
const AboutUsPage = lazy(() => import('./pages/pageContent/AboutUsPage'))

const ConnectedReferLink = lazy(() => import('./pages/referLink/referLink'))
const ConnectedLoginAuth = lazy(() => import('./auth/ConnectedLoginAuth'))
const ConnectedComplainBox = lazy(
  () => import('./pages/complain-box/ConnectedComplainBox'),
)
const ConnectedSecureAuth = lazy(
  () => import('./pages/secureAuth/ConnectedSecureAuth'),
)
const ConnectedHighLight = lazy(
  () => import('./pages/user/highlight/ConnectedHighLight'),
)
const ConnectedGameScreen = lazy(
  () => import('./pages/user/gameScreen/ConnectedGameScreen'),
)
const ConnectedSchedule = lazy(
  () => import('./pages/schedule/ConnectedSchedule'),
)
const ConnectedMatchSearch = lazy(
  () => import('./pages/public/ConnectedMatchSearch'),
)
const ConnectedWallet = lazy(
  () => import('./pages/common/myWallet/ConnectedWallet'),
)
const ConnectedWidrawal = lazy(
  () => import('./pages/widrawal/ConnectedWidrawal'),
)
const ConnectedDeposit = lazy(() => import('./pages/deposit/ConnectedDeposit'))
const ConnectedOpenBets = lazy(
  () => import('./pages/user/openBets/ConnectedOpenBets'),
)
const ConnectedButtonValues = lazy(
  () => import('./pages/user/buttonValues/ConnectedButtonValues'),
)
const ConnectTransaction = lazy(
  () => import('./pages/user/transaction/ConnectTransaction'),
)
const ConnectedNotification = lazy(
  () => import('./pages/admin/notification/ConnectedNotification'),
)
const UnderDevelopment = lazy(
  () => import('./component/underDevelopment/UnderDevelopment'),
)
const ConnectedProfile = lazy(
  () => import('./pages/common/profile/ConnectedProfile'),
)
const CommingSoonComponent = lazy(() => import('./component/404/CommingSoon'))
const Connected_My_Account = lazy(
  () => import('./pages/my_Account/Connected_My_Account'),
)
const CommonErrorPage = lazy(() => import('./component/404/CommonErrorPage'))
const ConnectedUserAccount = lazy(
  () => import('./pages/user/account/ConnectedAccount'),
)
const ConnectedBetHistory = lazy(
  () => import('./pages/user/betHistory/ConnectedBetHistory'),
)
const BettingExchange = lazy(
  () => import('./pages/pageContent/BettingExchange'),
)
const TopCasino = lazy(() => import('./pages/TopCasino/TopCasino'))

export const userRoutes = [
  {
    path: '/',
    exact: true,
    element: <ConnectedGame />,
  },
  {
    path: '/branch/:branchId',
    exact: true,
    element: <ConnectedLanding />,
  },
  {
    path: '/blogs',
    exact: true,
    element: <BlogPage />,
  },
  {
    path: '/blog-detail/:blogDetail',
    exact: true,
    element: <BlogDetailPage />,
  },
  {
    path: '/promotions',
    exact: true,
    element: <PromotionsPage />,
  },
  {
    path: '/promotions-detail/:detail',
    exact: true,
    element: <PromotionsDetailPage />,
  },
  {
    path: '/refer',
    exact: true,
    element: <ConnectedReferLink />,
  },
  {
    path: '/rules-and-regulation',
    exact: true,
    element: <RulesAndRegulation />,
  },
  {
    path: '/privacy-policy',
    exact: true,
    element: <PrivacyPolicyPage />,
  },
  {
    path: '/responsible-gambling',
    exact: true,
    element: <ResponsibleGambling />,
  },
  {
    path: '/about-us',
    exact: true,
    element: <AboutUsPage />,
  },
  {
    path: '/terms-and-condition',
    exact: true,
    element: <TermsAndConditionPage />,
  },
  {
    path: '/login-auth',
    exact: true,
    element: <ConnectedLoginAuth />,
  },
  {
    path: '/complain-box',
    exact: true,
    element: <ConnectedComplainBox />,
  },
  {
    path: '/settings/secureauth',
    exact: true,
    element: <ConnectedSecureAuth />,
  },
  {
    path: '/highlight',
    exact: true,
    element: <ConnectedHighLight />,
  },
  {
    path: '/game-screen',
    exact: true,
    element: <ConnectedGameScreen />,
  },
  {
    path: '/game-screen-play',
    exact: true,
    element: <ConnectedGameScreen />,
  },
  {
    path: '/:gameType',
    exact: true,
    element: <ConnectedGame />,
  },
  {
    path: '/schedule',
    exact: true,
    element: <ConnectedSchedule />,
  },
  {
    path: '/search',
    exact: true,
    element: <ConnectedMatchSearch />,
  },
  {
    path: '/live-casino',
    exact: true,
    element: <ConnectedLiveCansino />,
  },
  {
    path: '/lottery',
    exact: true,
    element: <ConnectedLotteryCansino />,
  },
  {
    path: '/virtual-games',
    exact: true,
    element: <ConnectedVirtualCansino />,
  },
  {
    path: '/crash-games',
    exact: true,
    element: <ConnectedCrashGame />,
  },
  {
    path: '/slot-games',
    exact: true,
    element: <ConnectedSlotsCansino />,
  },
  {
    path: '/my-wallet',
    exact: true,
    element: <ConnectedWallet />,
  },
  {
    path: '/top-casino',
    exact: true,
    element: <TopCasino />,
  },
  {
    path: '/withdraw',
    exact: true,
    element: <ConnectedWidrawal />,
  },
  {
    path: '/deposit',
    exact: true,
    element: <ConnectedDeposit />,
  },
  {
    path: '/accounts',
    exact: true,
    element: <ConnectedUserAccount />,
  },
  {
    path: '/live-casino',
    exact: true,
    element: <ConnectedLiveCansino />,
  },
  {
    path: '/bet-history',
    exact: true,
    element: <ConnectedBetHistory />,
  },
  {
    path: '/open-bets',
    exact: true,
    element: <ConnectedOpenBets />,
  },
  {
    path: '/events/:id',
    exact: true,
    element: <NewConnectedEvents />,
  },
  {
    path: '/button-values',
    exact: true,
    element: <ConnectedButtonValues />,
  },
  {
    path: '/transaction',
    exact: true,
    element: <ConnectTransaction />,
  },
  {
    path: '/my-wallet',
    exact: true,
    element: <ConnectedWallet />,
  },
  {
    path: '/notification',
    exact: true,
    element: <ConnectedNotification />,
  },
  {
    path: '*',
    exact: true,
    element: <UnderDevelopment />,
  },
  {
    path: '/my-wallet',
    exact: true,
    element: <ConnectedWallet />,
  },
  {
    path: '/profile',
    exact: true,
    element: <ConnectedProfile />,
  },
  {
    path: '/reset-password',
    exact: true,
    element: <UnderDevelopment />,
  },
  {
    path: '/rules-regulation',
    exact: true,
    element: <UnderDevelopment />,
  },
  {
    path: '/help',
    exact: true,
    element: <CommingSoonComponent isNotFullPage={true} />,
  },
  {
    path: '/account',
    exact: true,
    element: <Connected_My_Account />,
  },
  {
    path: '/live-market',
    exact: true,
    element: <CommingSoonComponent />,
  },
  {
    path: '*',
    exact: true,
    element: (
      <CommonErrorPage
        contentMessage='Sorry, the page your are looking for does not exist.'
        titleError='404'
        titleMessage='OPS! Page not found'
      />
    ),
  },
]

export const publicRoutes = [
  {
    path: '/',
    exact: true,
    element: <ConnectedLanding />,
  },
  {
    path: '/blogs',
    exact: true,
    element: <BlogPage />,
  },
  {
    path: '/blog-detail/:detail',
    exact: true,
    element: <BlogDetailPage />,
  },
  {
    path: '/promotions-detail/:detail',
    exact: true,
    element: <PromotionsDetailPage />,
  },
  {
    path: '/promotions',
    exact: true,
    element: <PromotionsPage />,
  },
  {
    path: '/top-casino',
    exact: true,
    element: <TopCasino />,
  },
  {
    path: '/about-us',
    exact: true,
    element: <AboutUsPage />,
  },
  {
    path: '/login-auth',
    exact: true,
    element: <ConnectedLoginAuth />,
  },
  {
    path: '/login-user/:userName/:password',
    exact: true,
    element: <ConnectedLanding />,
  },
  {
    path: '/branch/:branchId',
    exact: true,
    element: <ConnectedLanding />,
  },
  {
    path: '/rules-and-regulation',
    exact: true,
    element: <RulesAndRegulation />,
  },
  {
    path: '/privacy-policy',
    exact: true,
    element: <PrivacyPolicyPage />,
  },
  {
    path: '/responsible-gambling',
    exact: true,
    element: <ResponsibleGambling />,
  },
  {
    path: '/terms-and-condition',
    exact: true,
    element: <TermsAndConditionPage />,
  },
  {
    path: '/:gameType',
    exact: true,
    element: <ConnectedLanding />,
  },
  {
    path: '/search',
    exact: true,
    element: <ConnectedMatchSearch />,
  },
  {
    path: '/online-exchange',
    exact: true,
    element: <BettingExchange />,
  },
  {
    path: '/crash-games',
    exact: true,
    element: <ConnectedCrashGame />,
  },
  {
    path: '/live-casino',
    exact: true,
    element: <ConnectedLiveCansino />,
  },
  {
    path: '/lottery',
    exact: true,
    element: <ConnectedLotteryCansino />,
  },
  {
    path: '/virtual-games',
    exact: true,
    element: <ConnectedVirtualCansino />,
  },
  {
    path: '/slot-games',
    exact: true,
    element: <ConnectedSlotsCansino />,
  },
  {
    path: '/help',
    exact: true,
    element: <CommingSoonComponent isNotFullPage={true} />,
  },
  {
    path: '/sports-book',
    exact: true,
    element: <CommingSoonComponent isNotFullPage={true} />,
  },
  {
    path: '*',
    exact: true,
    element: (
      <CommonErrorPage
        contentMessage='Sorry, the page your are looking for does not exist.'
        titleError='404'
        titleMessage='OPS! Page not found'
      />
    ),
  },
]
