import { useDispatch, useSelector } from "react-redux"
import { CasinoTile } from "../casino/casinoTile"
import { emptyFunction } from "../../../../utils"
import { useCallback } from "react"
import { OPEN_LOGIN_SCREEN } from "../../../../redux/actions/userAction/betSlipAction"
import { ROYAL_GAME_LOGIN } from "../../../../redux/reducers/gamingReducer/royalGamingReducer"
import { APP_CONFIGURATION_SUB_PROVIDER_NAME, APP_CONFIGURATION_URL_GAME_CODE, APP_CONFIGURATION_URL_GAME_ID, APP_CONFIGURATION_URL_GAME_NAME, APP_CONFIGURATION_URL_PROVIDER_NAME, APP_CONFIGURATION_URL_TYPE } from "../../../../redux"
import { useLocation, useNavigate } from "react-router-dom"
import { SportsBannerSlider } from "./SportsBannerSlider"

export const LiveCasinoSection = (props: { divHeight: number }) => {
    const { liveCasinoData, liveCasinoLoading } = useSelector((state: any) => state.adminGames)
    const { isLogin } = useSelector((state: any) => state.login)
    const dispatch = useDispatch();
    const { pathname } = useLocation()
    const { isDarkMode } = useSelector((state: any) => state.theme)

    const navigate = useNavigate();

    const gameClick = useCallback(
        (
            gameId: string,
            gameName: string,
            providerName: string,
            subProviderNameLocal: string,
            gameCode: string,
        ) => {
            if (!isLogin) {
                return dispatch({
                    type: OPEN_LOGIN_SCREEN,
                    openLoginScreen: true,
                })
            }

            dispatch({
                type: ROYAL_GAME_LOGIN,
                payload: { url: '' },
                loading: false,
            })

            localStorage.setItem(APP_CONFIGURATION_URL_PROVIDER_NAME, providerName)
            localStorage.setItem(APP_CONFIGURATION_URL_GAME_ID, gameId.toString())
            localStorage.setItem(APP_CONFIGURATION_URL_GAME_CODE, gameCode.toString())
            localStorage.setItem(APP_CONFIGURATION_URL_GAME_NAME, gameName.toString())
            localStorage.setItem(APP_CONFIGURATION_URL_TYPE, pathname)
            localStorage.setItem(
                APP_CONFIGURATION_SUB_PROVIDER_NAME,
                subProviderNameLocal,
            )

            dispatch({
                type: APP_CONFIGURATION_URL_PROVIDER_NAME,
                payload: providerName,
            })
            dispatch({
                type: APP_CONFIGURATION_SUB_PROVIDER_NAME,
                payload: subProviderNameLocal,
            })
            dispatch({
                type: APP_CONFIGURATION_URL_GAME_ID,
                payload: gameId,
            })
            dispatch({
                type: APP_CONFIGURATION_URL_GAME_CODE,
                payload: gameCode,
            })
            dispatch({
                type: APP_CONFIGURATION_URL_GAME_NAME,
                payload: gameName,
            })
            dispatch({
                type: APP_CONFIGURATION_URL_TYPE,
                payload: pathname,
            })

            navigate(`/game-screen-play`)
        },
        [isLogin],
    )

    return (<>
        <SportsBannerSlider divHeight={isLogin ? (props.divHeight - 800) : props.divHeight} forceHeight={true}>
            {liveCasinoData && !liveCasinoLoading && liveCasinoData.length && liveCasinoData[0].subProviders[0].games.slice(0, 40).map((data: any, index: number) => {
                return (<div className="flex">
                    {!((index + 1) % 2) && <><div
                        key={index}
                        className={`flex flex-wrap w-[50%] h-[full] justify-center `}
                    >
                        <div
                            className='w-full h-full'
                            onClick={() =>
                                gameClick(
                                    data.gameId,
                                    data.gameName,
                                    data.providerName,
                                    data.subProviderName,
                                    data.gameCode,
                                )
                            }
                        >
                            <CasinoTile
                                isCasinoPage={true}
                                isPageVisible={true}
                                toggleAll={emptyFunction}
                                isDarkMode={isDarkMode}
                                casinoData={data}
                                liveCasino={true}
                            />
                        </div>
                    </div>
                        <div
                            key={liveCasinoData[0].subProviders[0].games[index + 1]._id}
                            className={`flex flex-wrap w-[50%] h-[full] justify-center `}
                        >
                            <div
                                className='w-full h-full'
                                onClick={() =>
                                    gameClick(
                                        liveCasinoData[0].subProviders[0].games[index + 1].gameId,
                                        liveCasinoData[0].subProviders[0].games[index + 1].gameName,
                                        liveCasinoData[0].subProviders[0].games[index + 1].providerName,
                                        liveCasinoData[0].subProviders[0].games[index + 1].subProviderName,
                                        liveCasinoData[0].subProviders[0].games[index + 1].gameCode,
                                    )
                                }
                            >
                                <CasinoTile
                                    isCasinoPage={true}
                                    isPageVisible={true}
                                    toggleAll={emptyFunction}
                                    isDarkMode={isDarkMode}
                                    casinoData={liveCasinoData[0].subProviders[0].games[index + 1]}
                                    liveCasino={true}
                                />
                            </div>
                        </div></>}
                </div>
                )
            })}
        </SportsBannerSlider>



    </>)
}