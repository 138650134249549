import { toast } from 'react-toastify'

const date = new Date()

// Format time
export const timeFormatter = new Intl.DateTimeFormat('en-US', {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
})

// Format date
export const dateFormatter = new Intl.DateTimeFormat('en-US', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
})

export function getYesterdayDate() {
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)

  const day = String(yesterday.getDate()).padStart(2, '0')
  const month = String(yesterday.getMonth() + 1).padStart(2, '0')
  const year = yesterday.getFullYear()

  return `${day}-${month}-${year}`
}

export function formatDateTime(dateTimeString: string) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  }
  const formattedDateTime = new Date(dateTimeString).toLocaleString(
    'en-US',
    options,
  )
  return formattedDateTime
}

export function formatDateTimeShort(dateTimeString: string) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
  const formattedDateTime = new Date(dateTimeString).toLocaleString(
    'en-US',
    options,
  )
  return formattedDateTime
}

export function checkDate(inputDateStr: string, _getLocalString: any) {
  const inputDate = new Date(inputDateStr)

  const currentDate = new Date()

  currentDate.setHours(0, 0, 0, 0)

  const tomorrowDate = new Date(currentDate)
  tomorrowDate.setDate(currentDate.getDate() + 1)

  inputDate.setHours(0, 0, 0, 0)

  if (inputDate.getTime() === currentDate.getTime()) {
    return _getLocalString('LOC_TODAY')
  } else if (inputDate.getTime() === tomorrowDate.getTime()) {
    return _getLocalString('LOC_TOMMOROW')
  } else {
    const formatDate = (date: any) => {
      const options = { day: '2-digit', month: 'short' }
      return date.toLocaleDateString('en-GB', options)
    }

    return formatDate(inputDate)
  }
}

const timeZoneOffset = -date.getTimezoneOffset()
// Format time zone offset
const offsetHours = Math.floor(Math.abs(timeZoneOffset) / 60)
const offsetMinutes = Math.abs(timeZoneOffset) % 60
const offsetSign = timeZoneOffset >= 0 ? '+' : '-'

export const currentDate = () => {
  // Combine the formatted components
  const formattedDateTime = `${formattedDate}   ${formattedTime}   ${formattedTimeZoneOffset}`

  return formattedDateTime
}
export const formattedDate = dateFormatter.format(date)
export const formattedTime = timeFormatter.format(date)
export const formattedTimeZoneOffset = `(${offsetSign}${offsetHours
  .toString()
  .padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')})`

export const getFormattedDate = (currentDate: Date, isEndDate?: boolean) => {
  var date = new Date(currentDate)
  var year = date.getFullYear()
  var month = ('0' + (date.getMonth() + 1)).slice(-2) // Months are zero indexed, so add 1
  var day = ('0' + date.getDate()).slice(-2)
  var hours = ('0' + date.getHours()).slice(-2)
  var minutes = ('0' + date.getMinutes()).slice(-2)
  var seconds = ('0' + date.getSeconds()).slice(-2)

  if (isEndDate) {
    hours = '23'
    minutes = '59'
    seconds = '59'
  }

  var formattedDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000z`
  return formattedDateString
}

export const getTimeZonedFormattedDateTime = (
  currentDate: Date,
  timeString: string,
) => {
  const day = currentDate.getDate()
  const month = currentDate.getMonth() + 1 // Months are zero-based, so add 1
  const year = currentDate.getFullYear()

  // Extract time components
  var timeComponents = timeString.match(/(\d{2}):(\d{2}):(\d{2})/)
  if (!timeComponents) {
    toast.error('Invalid Time Format')
    throw new Error('Invalid time format')
  }

  var hours = parseInt(timeComponents[1])
  var minutes = parseInt(timeComponents[2])
  var seconds = parseInt(timeComponents[3])
  const localDate = new Date(`${month}/${day}/${year}`)

  // Set time components
  localDate.setHours(hours)
  localDate.setMinutes(minutes)
  localDate.setSeconds(seconds)

  const isoString = localDate.toISOString()
  return isoString
}

export const showDateTimeFormat = (currentDate: string) => {
  const date = new Date(currentDate)
  const options: any = {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  }
  const formattedDate = date.toLocaleDateString('en-US', options)
  return formattedDate
}

export const getTime = (date: string) => {
  var dateTime = new Date(date)
  var hoursUTC = dateTime.getUTCHours()
  var minutesUTC = dateTime.getUTCMinutes()
  var secondsUTC = dateTime.getUTCSeconds()
  var localTime = new Date(
    Date.UTC(
      dateTime.getUTCFullYear(),
      dateTime.getUTCMonth(),
      dateTime.getUTCDate(),
      hoursUTC,
      minutesUTC,
      secondsUTC,
    ),
  )

  // Format the time as "hh:mm:ss"
  var formattedTime = localTime.toLocaleTimeString('en-US', { hour12: false })
  return formattedTime
}
