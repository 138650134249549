import React, { ReactNode } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import { FreeMode, Autoplay, Pagination, Navigation } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types/swiper-options'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
SwiperCore.use([FreeMode, Navigation])

interface SportsBannerSlidesProps {
  children: ReactNode[]
  isAutomatic?: boolean
  divHeight: number
  automaticTime?: number
  forceHeight?: boolean
}

export const SportsBannerSlider: React.FC<SportsBannerSlidesProps> = (
  props: SportsBannerSlidesProps,
) => {
  const swiperModules = [FreeMode, Navigation]
  const swiperOptions: SwiperOptions = {
    freeMode: false,
    wrapperClass: 'items-center ',
    navigation: {
      nextEl: '.swiper-button-next_book',
      prevEl: '.swiper-button-prev_book',
    },
    breakpoints: {
      0: {
        slidesPerView: 'auto',
        direction: 'horizontal',
        spaceBetween: 10,
      },
      1200: {
        slidesPerView: 'auto',
        direction: 'vertical',
        spaceBetween: 15,
      },
    },
  }

  const isVerticalMode = window.innerWidth >= 1200
  return (
    <>
      <Swiper
        id='sports-banner-swiper'
        loop={props.children && props.children.length > 4 ? true : false}
        speed={1500}
        parallax={true}
        freeMode={true}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 0,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        modules={[...swiperModules, Pagination, Autoplay]}
        {...swiperOptions}
        className='mySwiper'
        style={{
          height: props.forceHeight ? props.divHeight : isVerticalMode
            ? `${(props.divHeight < 0 ? props.divHeight : 1000) - 500}px`
            : 'auto',
        }}
      >
        <div className='swiper-wrapper'>
          {!!props.children &&
            !!props.children.length &&
            props.children.map((item, index) => (
              <SwiperSlide
                id='swiperSlideChildren'
                key={index}
                style={{ width: '100%', paddingBottom: '1rem' }}
                className='swiper-slide lg:max-w-full md:max-w-[40%] sm:max-w-[50%] max-w-[70%] lg:!h-max'
              >
                {item}
              </SwiperSlide>
            ))}
        </div>
      </Swiper>
      {/* <div className='absolute right-0 hidden gap-5 top-2 lg:flex'>
        <div className='dark:text-white text-black swiper-button-next_book'>
          <ArrowCircleDownIcon />
        </div>
        <div className='dark:text-white text-black swiper-button-prev_book'>
          <ArrowCircleUp />
        </div>
      </div> */}
    </>
  )
}
