import { useDispatch, useSelector } from 'react-redux'
import { CasinoTile } from '../../landing/component/casino/casinoTile'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { Box, CircularProgress, useMediaQuery } from '@mui/material'
import {
  APP_CONFIGURATION_GAME_PATH_NAME,
  APP_CONFIGURATION_GAME_TYPE,
  APP_CONFIGURATION_SELECTED_INNER_BOX,
  APP_CONFIGURATION_SUB_PROVIDER_DATA,
  APP_CONFIGURATION_SUB_PROVIDER_NAME,
  APP_CONFIGURATION_SUB_PROVIDER_TYPE,
  APP_CONFIGURATION_URL_GAME_ID,
  APP_CONFIGURATION_URL_GAME_CODE,
  APP_CONFIGURATION_URL_GAME_NAME,
  APP_CONFIGURATION_URL_PROVIDER_NAME,
  APP_CONFIGURATION_URL_TYPE,
  APP_SELECTED_EVENT_ID,
  isAnyEuropianGame,
  EUROPIAN_GAME,
  getUserCasinoList,
} from '../../../redux'
import { emptyFunction, getAssetUrl } from '../../../utils'
import { ROYAL_GAME_LOGIN } from '../../../redux/reducers/gamingReducer/royalGamingReducer'
import { makePostHttpCallAsync } from '../../../api'
import { isMobileScreensix } from '../../../utils/internalUtils'
import { decrypt } from '../../../api/authHelper'
import { Helmet } from 'react-helmet'
import { Content } from './Content'
import { useRef } from 'react'
import { isActualFuture9 } from '../../../App'
import { SearchableSearchBox } from '../../../component/SearchableSearchBox'
import { IoSearch } from 'react-icons/io5'

export interface ILiveCasinoContent {
  isDarkMode: boolean
  casinoLoader: boolean
  selectedGameType: any
  selectedInnerBox: string
  gamePathName: any
  selectedSubProvider: any
  updateOpenLoginScreen: any
  isLogin?: boolean
  selectedSubProviderData: any
  liveCasinoData: any
  liveCasinoLoading: boolean
  virtualCasinoData: any
  deletedEventId: string
  virtualCasinoLoading: boolean
  crashGamesLoading: boolean
  crashGamesData: any
  slotGamesData: any
  slotGamesLoading: boolean
  lotteryGamesLoading: boolean
  lotteryGamesData: any
}

export const EZUGI_REMOVE_GAME = new Map([
  ['ezugi_228000', true],
  ['ezugi_541000', true],
  ['ezugi_221000', true],
  ['ezugi_221002', true],
  ['ezugi_227101', true],
  ['ezugi_150', true],
])

export const AVIATOR_REMOVE_GAME = new Map([
  ['aviator', true],
  ['mines', true],
])

export const EZUGI_DEFAULT_PRORITY = [
  {
    gameId: 'ezugi_228000',
    gameName: 'Andar Bahar',
    category: 'Live Casino',
    providerName: 'ezugi',
    providerType: 'ezugi',
    subProviderName: 'Ezugi',
    status: 'active',
    urlThumb: 'https://assets.future09.com/asset/casino/ezugi/Andar Bahar.webp',
    gameCode: '38',
    gameCategory: 'Live Casino',
    gameType: 'Andar Bahar',
    gameCategoryArray: ['Live Casino'],
  },
  {
    gameId: 'ezugi_541000',
    gameName: 'Ultimate Roulette',
    category: 'Live Casino',
    providerName: 'ezugi',
    providerType: 'ezugi',
    subProviderName: 'Ezugi',
    status: 'active',
    urlThumb:
      'https://assets.future09.com/asset/casino/ezugi/Ultimate Roulette.webp',
    gameCode: '54',
    gameCategory: 'Live Casino',
    gameType: 'Roulette',
    gameCategoryArray: ['Live Casino'],
  },
  {
    gameId: 'ezugi_221000',
    gameName: 'Speed Roulette',
    category: 'Live Casino',
    providerName: 'ezugi',
    providerType: 'ezugi',
    subProviderName: 'Ezugi',
    status: 'active',
    urlThumb:
      'https://assets.future09.com/asset/casino/ezugi/Speed Roulette.webp',
    gameCode: '3',
    gameCategory: 'Live Casino',
    gameType: 'Roulette',
    gameCategoryArray: ['Live Casino'],
  },
  {
    gameId: 'ezugi_221002',
    gameName: 'Speed Auto Roulette',
    category: 'Live Casino',
    providerName: 'ezugi',
    providerType: 'ezugi',
    subProviderName: 'Ezugi',
    status: 'active',
    urlThumb:
      'https://assets.future09.com/asset/casino/ezugi/Speed Auto Roulette.webp',
    gameCode: '7',
    gameCategory: 'Live Casino',
    gameType: 'Roulette',
    gameCategoryArray: ['Live Casino'],
  },
  {
    gameId: 'ezugi_227101',
    gameName: 'Bet on Teen Patti',
    category: 'Live Casino',
    providerName: 'ezugi',
    providerType: 'ezugi',
    subProviderName: 'Ezugi',
    status: 'active',
    urlThumb:
      'https://assets.future09.com/asset/casino/ezugi/Bet on Teen Patti.webp',
    gameCode: '16',
    gameCategory: 'Live Casino',
    gameType: 'Teen Patti',
    gameCategoryArray: ['Live Casino'],
  },
  {
    gameId: 'ezugi_150',
    gameName: 'Dragon Tiger',
    category: 'Live Casino',
    providerName: 'ezugi',
    providerType: 'ezugi',
    subProviderName: 'Ezugi',
    status: 'active',
    urlThumb: 'https://assets.future09.com/asset/casino/ezugi/Dragon Tiger.webp',
    gameCode: '24',
    gameCategory: 'Live Casino',
    gameType: 'Baccarat',
    gameCategoryArray: ['Live Casino'],
  },
]

export const AVIATOR_DEFAULT_PRORITY = [
  {
    gameId: 'aviator',
    gameName: 'Aviator',
    category: 'Crash Games',
    providerName: 'spribe',
    subProviderName: 'Aviator',
    status: 'ACTIVE',
    urlThumb: 'https://assets.future09.com/asset/casino/spribe/Aviator.webp',
    gameCode: 'spribe_aviator',
    gameCategory: 'spribe_aviator',
    gameType: 'Aviator',
    providerType: 'spribe',
    gameCategoryArray: ['Crash Games', 'Live Casino'],
  },
  {
    gameId: 'mines',
    gameName: 'Mines',
    category: 'Crash Games',
    providerName: 'spribe',
    subProviderName: 'Aviator',
    status: 'ACTIVE',
    urlThumb: 'https://assets.future09.com/asset/casino/spribe/Mines.webp',
    gameCode: 'spribe_crypto',
    gameCategory: 'spribe_crypto',
    gameType: 'Mines',
    providerType: 'spribe',
    gameCategoryArray: ['Crash Games', 'Live Casino'],
  },
]

export const DEFAULT_EVOLUTION_TABLE_ENTRY = [
  {
    image:
      'https://mediumrare.imgix.net/d114d861066b1ecb1b08e665ae3eeb5cd8218f4e2c7d3d5e8142d65a72f0e017?&dpr=2&format=auto&auto=format&q=50',
    category: 'game_shows',
    providerType: 'evolution',
    gameCode: 'code',
  },
  {
    image:
      'https://mediumrare.imgix.net/2031c9083f859d35405e6a506507898aeebe2c1b407b64e94aaa26189f5265bf?&dpr=2&format=auto&auto=format&q=50',
    category: 'baccarat_sicbo',
    providerType: 'evolution',
    gameCode: 'code',
  },
  {
    image:
      'https://mediumrare.imgix.net/15eb3f8c501ca7a682a79f145f6ada73e958b8151cc726bdef699dc3e9f869ab?&dpr=2&format=auto&auto=format&q=50',
    category: 'poker',
    providerType: 'evolution',
    gameCode: 'code',
  },
  {
    image:
      'https://mediumrare.imgix.net/042eaf5a9e5bf27a65d2ac21bc5b3585410c46a608b1130705f41ef24026c5d2?&dpr=2&format=auto&auto=format&q=50',
    category: 'top_games',
    providerType: 'evolution',
    gameCode: 'code',
  },
  {
    image:
      'https://mediumrare.imgix.net/2c030508b8732644f48a0ac8dc243fd3051023e2035b39895535d195bf1c392e?&dpr=2&format=auto&auto=format&q=50',
    category: 'roulette',
    providerType: 'evolution',
    gameCode: 'code',
  },
  {
    image:
      'https://mediumrare.imgix.net/28a1eb425b100bd463a2ef9093b22e494296b982851e59f7311c619f67c75a66?&dpr=2&format=auto&auto=format&q=50',
    category: 'reward_games',
    providerType: 'evolution',
    gameCode: 'code',
  },
  {
    image:
      'https://mediumrare.imgix.net/090fed78786a43c7a4e01368d2a5ae1be1df0eb1b0c46b61f230153d53615a40?&dpr=1&format=auto&auto=format&q=50',
    category: 'blackjack',
    providerType: 'evolution',
    gameCode: 'code',
  },
  {
    image:
      'https://mediumrare.imgix.net/231bc2dc6001601c6d9dd4870350e4b1b0d5f56d17fe3e69099c401f03b31871?&dpr=2&format=auto&auto=format&q=50',
    category: 'slots',
    providerType: 'evolution',
    gameCode: 'code',
  },
]

export const DEFAULT_LIVE_CASINO = [
  {
    image: 'casino-suggestion/live-casino/3 CARD POKER.webp',
    category: 'Live Casino',
    gameId: 'TRPTable00000001',
    gameName: 'Triple Card Poker',
    providerName: 'evolutionGaming',
    subProviderName: 'evolutionGaming',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/live-casino/ANDAR BAHAR.webp',
    category: 'Live Casino',
    gameId: '42001611',
    gameName: 'Andar Bahar',
    providerName: 'creedRoom',
    subProviderName: 'creedRoom',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/live-casino/BACCARAT.webp',
    category: 'Live Casino',
    gameId: '900006',
    gameName: 'Baccarat',
    providerName: 'RG',
    subProviderName: 'Royal Gaming',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/live-casino/BLACKJACK.webp',
    category: 'Live Casino',
    gameId: '420016100',
    gameName: 'Speed Blackjack',
    providerName: 'creedRoom',
    subProviderName: 'creedRoom',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/live-casino/CASINO HOLDEM.webp',
    category: 'Live Casino',
    gameId: '400040784',
    gameName: 'Casino Holdem',
    providerName: 'creedRoom',
    subProviderName: 'creedRoom',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/live-casino/HI LOW.webp',
    category: 'Live Casino',
    gameId: '902005',
    gameName: 'Hi Low Mini ',
    providerName: 'RG',
    subProviderName: 'Yuvi Games',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/live-casino/LUCKY 7.webp',
    category: 'Live Casino',
    gameId: '900021',
    gameName: 'Lucky7',
    providerName: 'RG',
    subProviderName: 'Royal Gaming',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/live-casino/ROULETTE.webp',
    category: 'Live Casino',
    gameId: '900075',
    gameName: 'Roulette',
    providerName: 'RG',
    subProviderName: 'Royal Gaming',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/live-casino/TEENPATTI.webp',
    category: 'Live Casino',
    gameId: '900019',
    gameName: 'One Day Teenpatti',
    providerName: 'RG',
    subProviderName: 'Royal Gaming',
    gameCode: 'code',
  },
]

export const DEFAULT_VIRTUAL_CASINO = [
  {
    image: 'casino-suggestion/virtual-casino/2 CARD TEENPATTI ONE DAY.webp',
    category: 'Virtual',
    gameId: '901038',
    gameName: '2 card Teen Patti One day VR ',
    providerName: 'RG',
    subProviderName: 'Royal Gaming Virtual',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/virtual-casino/2 CARD TEENPATTI.webp',
    category: 'Virtual',
    gameId: '902008',
    gameName: '2 card Teen Patti Mini',
    providerName: 'RG',
    subProviderName: 'Royal Gaming Virtual',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/virtual-casino/32 CARDS.webp',
    category: 'Virtual',
    gameId: '901020',
    gameName: 'VR 32 Cards',
    providerName: 'RG',
    subProviderName: 'Royal Gaming',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/virtual-casino/AKBAR ROMEO.webp',
    category: 'Virtual',
    gameId: '901022',
    gameName: 'VR Akbar Romeo Walter',
    providerName: 'RG',
    subProviderName: 'Roayl Gaming',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/virtual-casino/BACCARAT.webp',
    category: 'Virtual',
    gameId: '902015',
    gameName: 'Baccarat Mini',
    providerName: 'RG',
    subProviderName: 'Royal Casino',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/virtual-casino/CRICKET WAR.webp',
    category: 'Virtual',
    gameId: '902006',
    gameName: 'Cricket War Mini',
    providerName: 'RG',
    subProviderName: 'ROYAL CASINO',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/virtual-casino/DRAGON TIGER ONE DAY.webp',
    category: 'Virtual',
    gameId: '900027',
    gameName: 'Dragon Tiger One day',
    providerName: 'RG',
    subProviderName: 'Royal Gaming',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/virtual-casino/DRAGON TIGER.webp',
    category: 'Virtual',
    gameId: '40003097',
    gameName: 'Dragon Tiger',
    providerName: 'creedRoom',
    subProviderName: 'creedRoom',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/virtual-casino/POKER.webp',
    category: 'Virtual',
    gameId: '400031662',
    gameName: 'Russian Poker',
    providerName: 'creedRoom',
    subProviderName: 'creedRoom',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/virtual-casino/ROULETTE.webp',
    category: 'Virtual',
    gameId: '901002',
    gameName: 'VR Auto Roulette',
    providerName: 'RG',
    subProviderName: 'Royal Gaming',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/virtual-casino/TEENPATTI MUFLIS.webp',
    category: 'Virtual',
    gameId: '901041',
    gameName: 'Muflis TeenPatti One day VR',
    providerName: 'RG',
    subProviderName: 'Royal Gaming',
    gameCode: 'code',
  },
]

export const DEFAULT_CRASH_GAMES = [
  {
    image: 'casino-suggestion/crash-games/CASH OR CRASH.webp',
    category: 'Crash Games',
    gameId: 'CashOrCrash00001',
    gameName: 'Cash or Crash',
    providerName: 'evolutionGaming',
    subProviderName: 'evolutionGaming',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/crash-games/CRASH EXTREME.webp',
    category: 'Crash Games',
    gameId: '704',
    gameName: 'Crash Extreme',
    providerName: 'popOk',
    subProviderName: 'Crash',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/crash-games/CRASH INFINITY.webp',
    category: 'Crash Games',
    gameId: '702',
    gameName: 'Crash Infinity',
    providerName: 'popOk',
    subProviderName: 'Crash',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/crash-games/CRASHO SAURUS.webp',
    category: 'Crash Games',
    gameId: '706',
    gameName: 'CrashoSaurus',
    providerName: 'popOk',
    subProviderName: 'Crash',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/crash-games/DICE.webp',
    category: 'Crash Games',
    gameId: '703',
    gameName: 'Dice',
    providerName: 'popOk',
    subProviderName: 'Crash',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/crash-games/LUCKY SPIN.webp',
    category: 'Crash Games',
    gameId: '701',
    gameName: 'Lucky Spin',
    providerName: 'popOk',
    subProviderName: 'Spin',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/crash-games/RED HOT LINE 2.webp',
    category: 'Crash Games',
    gameId: '700',
    gameName: 'Red Hot Line 2',
    providerName: 'popOk',
    subProviderName: 'Crash',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/crash-games/STOCK MARKET.webp',
    category: 'Crash Games',
    gameId: 'StockMarket00001',
    gameName: 'Stock Market',
    providerName: 'evolutionGaming',
    subProviderName: 'evolutionGaming',
    gameCode: 'code',
  },
]

export const DEFAULT_SLOTS_GAMES = [
  {
    image: 'casino-suggestion/slots/BLAZING HOT.webp',
    category: 'Slot Games',
    gameId: '1',
    gameName: 'Blazing Hot',
    providerName: 'popOk',
    subProviderName: 'Hot Games',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/slots/DIAMOND FLASH.webp',
    category: 'Slot Games',
    gameId: '2',
    gameName: 'DIAMOND FLASH',
    providerName: 'popOk',
    subProviderName: 'Hot Games',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/slots/ERA OF DRAGONS.webp',
    category: 'Slot Games',
    gameId: '36',
    gameName: 'Era Of Dragons',
    providerName: 'popOk',
    subProviderName: 'Slot Games',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/slots/HOT ANGELS.webp',
    category: 'Slot Games',
    gameId: '40',
    gameName: 'Hot Angels',
    providerName: 'popOk',
    subProviderName: 'Slot Games',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/slots/HUGA CHAGA.webp',
    category: 'Slot Games',
    gameId: '30',
    gameName: 'Huga Chaga',
    providerName: 'popOk',
    subProviderName: 'Slot Games',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/slots/LOST TREASURE.webp',
    category: 'Slot Games',
    gameId: '27',
    gameName: 'Lost Treasure',
    providerName: 'popOk',
    subProviderName: 'Slot Games',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/slots/NOVEL OF ALEXANDER.webp',
    category: 'Slot Games',
    gameId: '22',
    gameName: 'Novel of Alexander',
    providerName: 'popOk',
    subProviderName: 'Slot Games',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/slots/SULTANS TALE.webp',
    category: 'Slot Games',
    gameId: '23',
    gameName: 'Sultan"s tale',
    providerName: 'popOk',
    subProviderName: 'Slot Games',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/slots/VIKINGS MYSTERY.webp',
    category: 'Slot Games',
    gameId: '38',
    gameName: 'Vikings Mystery',
    providerName: 'popOk',
    subProviderName: 'Slot Games',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/slots/YUMMY TRICK OR TREAT.webp',
    category: 'Slot Games',
    gameId: '31',
    gameName: 'Yummy: Trick or Treat',
    providerName: 'popOk',
    subProviderName: 'Slot Games',
    gameCode: 'code',
  },
]

export const DEFAULT_LOTTERY_GAMES = [
  {
    image: 'casino-suggestion/lottery/CASINO WAR.webp',
    category: 'Lottery',
    gameId: '900009',
    gameName: 'Casino War',
    providerName: 'RG',
    subProviderName: 'Royal Gaming',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/lottery/DEAL NO DEAL.webp',
    category: 'Lottery',
    gameId: 'dealnodeal000001',
    gameName: 'Deal or No Deal',
    providerName: 'evolutionGaming',
    subProviderName: 'evolutionGaming',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/lottery/LOTTERY.webp',
    category: 'Lottery',
    gameId: '900012',
    gameName: 'Lottery',
    providerName: 'RG',
    subProviderName: 'Royal Gaming',
    gameCode: 'code',
  },
  {
    image: 'casino-suggestion/lottery/VR LOTTERY.webp',
    category: 'Lottery',
    gameId: '901012',
    gameName: 'Cash or Crash',
    providerName: 'RG',
    subProviderName: 'Royal Gaming Virtual',
    gameCode: 'code',
  },
]

export const Account: React.FC<ILiveCasinoContent> = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { pathname } = useLocation()
  const selectedTitle = localStorage.getItem('selectedGameTile')
  const { logoText } = useSelector((state: any) => state.appThemeConfiguration)
  const [selectedBox, setSelectedBox] = useState<string>(
    props.selectedSubProvider,
  )
  const currentDomain = useSelector((state: any) => state.currentDomain)
  const [navEnable, setNavEnable] = useState(true)
  const [selectedBoxData, setSelectedBoxData] = useState<any[]>([])
  const [clickedItem, setClickedItem] = useState(props.selectedGameType || [])
  const [search, setSearch] = useState('')
  const [gameType, setGameType] = useState('')
  const { id } = useParams()
  const [decryptId] = decrypt(id)
  const dispatch = useDispatch()
  const isSmallestSmallScreen = useMediaQuery('(max-width:430px)')

  const getSuggestionImages = useCallback(() => {
    switch (pathname) {
      case '/live-casino': {
        return [...DEFAULT_LIVE_CASINO, ...DEFAULT_CRASH_GAMES]
      }

      case '/virtual-casino': {
        return DEFAULT_VIRTUAL_CASINO
      }

      case '/crash-games': {
        return DEFAULT_CRASH_GAMES
      }

      case '/slot-games': {
        return DEFAULT_SLOTS_GAMES
      }

      case '/lottery': {
        return DEFAULT_LOTTERY_GAMES
      }

      default:
        return DEFAULT_LIVE_CASINO
    }
  }, [pathname])

  useEffect(() => {
    let casinoData: any[] = []
    if (props.casinoLoader) {
      if (pathname === '/live-casino') {
        casinoData = props.liveCasinoData
      }

      if (pathname === '/virtual-games') {
        casinoData = props.virtualCasinoData || []
      }

      if (pathname === '/crash-games') {
        casinoData = props.crashGamesData || []
      }

      if (pathname === '/slot-games') {
        casinoData = props.slotGamesData || []
      }

      if (pathname === '/lottery') {
        casinoData = props.lotteryGamesData || []
      }

      if (casinoData && casinoData.length) {
        setCasinoDataForPage(casinoData)
      }
    }
  }, [pathname, props.casinoLoader])

  useEffect(() => {
    if (pathname === '/live-casino') {
      !props.liveCasinoData ||
        (!props.liveCasinoData.length &&
          dispatch(getUserCasinoList(currentDomain, 'Live Casino') as any))
    }

    if (pathname === '/virtual-games') {
      !props.virtualCasinoData ||
        (!props.virtualCasinoData.length &&
          dispatch(getUserCasinoList(currentDomain, 'Virtual Games') as any))
    }

    if (pathname === '/crash-games') {
      !props.crashGamesData ||
        (!props.crashGamesData.length &&
          dispatch(getUserCasinoList(currentDomain, 'Crash Games') as any))
    }

    if (pathname === '/slot-games') {
      !props.slotGamesData ||
        (!props.slotGamesData.length &&
          dispatch(getUserCasinoList(currentDomain, 'Slot Games') as any))
    }

    if (pathname === '/lottery') {
      !props.lotteryGamesData ||
        (!props.lotteryGamesData.length &&
          dispatch(getUserCasinoList(currentDomain, 'Lottery') as any))
    }
  }, [pathname])

  const [casinoDataForPage, setCasinoDataForPage] = useState<any[]>([])

  useEffect(() => {
    if (selectedBoxData?.length && props.selectedInnerBox) {
      let items: any[] = []
      for (let index = 0; index < selectedBoxData.length; index++) {
        if (selectedBoxData[index].gameType === props.selectedInnerBox) {
          items = selectedBoxData[index].games
        }
      }

      if (!(items && items.length)) {
        items = selectedBoxData[0].games
      }

      setClickedItem(items)
      dispatch({
        type: APP_CONFIGURATION_GAME_TYPE,
        payload: items,
      })
      dispatch({
        type: APP_CONFIGURATION_SELECTED_INNER_BOX,
        payload: '',
      })
    }
  }, [props.selectedInnerBox, selectedBoxData])

  useEffect(() => {
    if (!decryptId && props.deletedEventId) {
      dispatch({
        type: APP_SELECTED_EVENT_ID,
        payload: '',
      })
    }

    if (decryptId) {
      dispatch({
        type: APP_SELECTED_EVENT_ID,
        payload: decryptId,
      })
    }
  }, [decryptId])

  useEffect(() => {
    const gameCategoy = casinoDataForPage && casinoDataForPage[0]

    if (!gameCategoy) {
      return
    }

    if (selectedTitle && navEnable) {
      setSelectedBox(selectedTitle)
      const selecteditemGame = casinoDataForPage?.filter((item: any) => {
        return item.subProviderName === selectedTitle
      })
      setSelectedBoxData(selecteditemGame[0]?.subProviders)
      setClickedItem(selecteditemGame[0]?.subProviders[0].games)
      setNavEnable(false)
    } else {
      if (pathname !== props.gamePathName) {
        dispatch({
          type: APP_CONFIGURATION_URL_GAME_ID,
          payload: '',
        })
        dispatch({
          type: APP_CONFIGURATION_URL_GAME_CODE,
          payload: '',
        })
        dispatch({
          type: APP_CONFIGURATION_URL_TYPE,
          payload: pathname,
        })
        dispatch({
          type: APP_CONFIGURATION_SUB_PROVIDER_TYPE,
          payload: {},
        })
        dispatch({
          type: APP_CONFIGURATION_SUB_PROVIDER_DATA,
          payload: [],
        })
        dispatch({
          type: APP_CONFIGURATION_GAME_PATH_NAME,
          payload: pathname,
        })
        dispatch({
          type: APP_CONFIGURATION_GAME_TYPE,
          payload: [],
        })
        setSelectedBox(gameCategoy.subProviderName)
        setClickedItem(gameCategoy.subProviders[0].games)
        setSelectedBoxData(gameCategoy.subProviders)
      }

      if (!props.selectedSubProviderData.length) {
        gameCategoy && setSelectedBox(gameCategoy.subProviderName)
        gameCategoy && setClickedItem(gameCategoy.subProviders[0].games)
        gameCategoy && setSelectedBoxData(gameCategoy.subProviders)
        dispatch({
          type: APP_CONFIGURATION_URL_TYPE,
          payload: pathname,
        })
      } else {
        // TODO: REASON TO COMMENT THESE
        setSelectedBox(props.selectedSubProvider)
        setClickedItem(props.selectedGameType)
        setSelectedBoxData(props.selectedSubProviderData)
      }
    }
  }, [pathname, casinoDataForPage, location])

  useEffect(() => {
    if (isActualFuture9() && currentDomain.type === 'b2c') {
      let titleLocal = ''
      if (pathname === '/live-casino')
        titleLocal = 'Top Live Casino Online Games - Play & Win Big | Future9'
      if (pathname === '/virtual-games')
        titleLocal = 'Discover the Best Virtual Games - Play Now | Future9'
      if (pathname === '/crash-games')
        titleLocal = 'Top Crash Games Online - Play & Win Instantly | Future9'
      if (pathname === '/slot-games')
        titleLocal = 'Play the Best Slot Games for Real Money | Future9'
      if (pathname === '/lottery')
        titleLocal = 'Buy Lottery Tickets Online Today | Future9'

      document.title = titleLocal

      return () => {
        // TODO: TITLE COMMENT
        // document.title = logoText + ' | Most Trusted Sports & Casino Platform'
      }
    }
  }, [])

  const getTitleForContent = useCallback((): any => {
    if (pathname === '/live-casino') {
      return {
        name: `Top Live Casino Online Games - Play & Win Big`,
        translatedName: _getLocalString('LOC_LIVE_CASINO'),
        description:
          'Play top live casino online games and win big! Join now for real dealers, exclusive bonuses, and thrilling live gaming from home. Start winning today',
        translatedDescription:
          _getLocalString('LOC_BET_NOW_ON_MULTIPLE') +
          ' ' +
          _getLocalString('LOC_LIVE_CASINO'),
        keyword: 'live casino games,  Live Casino',
      }
    }

    if (pathname === '/virtual-games') {
      return {
        name: `Discover the Best Virtual Games - Play Now`,
        translatedName: _getLocalString('LOC_VIRTUAL_GAME'),
        description:
          'Discover and play the best virtual games online now! Explore a world of exciting gameplay and entertainment. Start your adventure today',
        translatedDescription:
          _getLocalString('LOC_BET_NOW_ON_MULTIPLE') +
          ' ' +
          _getLocalString('LOC_VIRTUAL_GAME'),
        keyword:
          'Crash Game Online, Rocket Crash Game Real Money, Paisa Wala game,  Crash Game Real Money',
      }
    }

    if (pathname === '/crash-games') {
      return {
        name: `Top Crash Games Online - Play & Win Instantly`,
        translatedName: _getLocalString('LOC_CRASH_GAME'),
        description:
          'Play the top crash games online and win instantly! Join now for exciting gameplay, big rewards, and nonstop action. Start winning today',
        translatedDescription:
          _getLocalString('LOC_BET_NOW_ON_MULTIPLE') +
          ' ' +
          _getLocalString('LOC_CRASH_GAME'),
        keyword:
          'Crash Game Online, Rocket Crash Game Real Money, Paisa Wala game,  Crash Game Real Money',
      }
    }

    if (pathname === '/slot-games') {
      return {
        name: `Play the Best Slot Games for Real Money`,
        translatedName: _getLocalString('LOC_SLOT_GAMES'),
        description:
          'Play the best slot games for real money at Future 9! Start winning with thrilling gameplay and exciting bonuses. Join now for Play Online',
        translatedDescription:
          _getLocalString('LOC_BET_NOW_ON_MULTIPLE') +
          ' ' +
          _getLocalString('LOC_SLOT_GAMES'),
        keyword:
          'India Slot, Online Slot game Real Money, Slot games that pay real money, Best Slot Games to Win Money, India Slot, Online Slot game Real Money,  Best Slot Games to Win Money',
      }
    }

    if (pathname === '/lottery') {
      return {
        name: ``,
        translatedName: _getLocalString('LOC_LOTTERY_PAGE'),
        description:
          'Lottery Tickets online for a chance to win big! Easy purchase, quick results, and huge prizes await. Get your tickets now!',
        translatedDescription:
          _getLocalString('LOC_BET_NOW_ON_MULTIPLE') +
          ' ' +
          _getLocalString('LOC_LOTTERY_PAGE'),
        keyword: 'Lottery',
      }
    }
  }, [pathname])

  useEffect(() => {
    return () => {
      localStorage.removeItem('selectedGameTile')
    }
  }, [])

  const handleBoxClick = (text: string, data: any): void => {
    setSelectedBoxData(data)
    localStorage.setItem('selectedGameTile', text)
    setSelectedBox(text)
    setClickedItem(data[0].games)
    dispatch({
      type: APP_CONFIGURATION_SUB_PROVIDER_TYPE,
      payload: text,
    })
    dispatch({
      type: APP_CONFIGURATION_SUB_PROVIDER_DATA,
      payload: data,
    })
    dispatch({
      type: APP_CONFIGURATION_GAME_TYPE,
      payload: data[0].games,
    })
    localStorage.setItem(APP_CONFIGURATION_URL_PROVIDER_NAME, text)
    localStorage.setItem(APP_CONFIGURATION_URL_TYPE, pathname)
  }

  const navigate = useNavigate()

  const handleItemClick = (data: any, gameType: string) => {
    dispatch({
      type: APP_CONFIGURATION_GAME_TYPE,
      payload: data,
    })
    setClickedItem(data)
    setGameType(gameType)
  }

  const gameClick = useCallback(
    (
      gameId: string,
      gameName: string,
      providerName: string,
      subProviderNameLocal: string,
      gameCode: string,
    ) => {
      if (!props.isLogin) {
        return props.updateOpenLoginScreen(true)
      }

      dispatch({
        type: ROYAL_GAME_LOGIN,
        payload: { url: '' },
        loading: false,
      })

      localStorage.setItem(APP_CONFIGURATION_URL_PROVIDER_NAME, providerName)
      localStorage.setItem(APP_CONFIGURATION_URL_GAME_ID, gameId.toString())
      localStorage.setItem(APP_CONFIGURATION_URL_GAME_CODE, gameCode.toString())
      localStorage.setItem(APP_CONFIGURATION_URL_GAME_NAME, gameName.toString())
      localStorage.setItem(APP_CONFIGURATION_URL_TYPE, pathname)
      localStorage.setItem(
        APP_CONFIGURATION_SUB_PROVIDER_NAME,
        subProviderNameLocal,
      )

      dispatch({
        type: APP_CONFIGURATION_URL_PROVIDER_NAME,
        payload: providerName,
      })
      dispatch({
        type: APP_CONFIGURATION_SUB_PROVIDER_NAME,
        payload: subProviderNameLocal,
      })
      dispatch({
        type: APP_CONFIGURATION_URL_GAME_ID,
        payload: gameId,
      })
      dispatch({
        type: APP_CONFIGURATION_URL_GAME_CODE,
        payload: gameCode,
      })
      dispatch({
        type: APP_CONFIGURATION_URL_GAME_NAME,
        payload: gameName,
      })
      dispatch({
        type: APP_CONFIGURATION_URL_TYPE,
        payload: pathname,
      })

      navigate(`/game-screen-play`)
    },
    [props?.isLogin],
  )

  const [isHovered, setIsHovered] = useState(false)
  const isLargeScreen = useMediaQuery('(min-width:1200px)')
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const { type } = useSelector((state: any) => state.currentDomain)

  const evolutionGaming = (category: string) => {
    const urlencoded = new URLSearchParams()
    urlencoded.append('gameId', 'rng-topcard00001')
    urlencoded.append('channel', isMobileScreensix() ? 'm' : 'p')
    urlencoded.append('category', category)
    dispatch({
      type: ROYAL_GAME_LOGIN,
      payload: { url: '' },
      loading: true,
    })
    navigate(`/game-screen-play`)
    dispatch({
      type: APP_CONFIGURATION_URL_PROVIDER_NAME,
      payload: 'NO_CALL',
    })
    makePostHttpCallAsync(
      dispatch,
      'api/user/evolutionGaming/login',
      type,
      urlencoded,
      '',
      true,
      'application/x-www-form-urlencoded',
    )
      .then((res: any) => {
        dispatch({
          type: ROYAL_GAME_LOGIN,
          payload: { url: res.docs.url1 },
          loading: false,
        })
      })
      .catch(() => {
        dispatch({
          type: ROYAL_GAME_LOGIN,
          payload: { url: '' },
          loading: false,
        })
      })
  }

  const containerRef = useRef<HTMLDivElement>(null)
  const [isOverflowing, setIsOverflowing] = useState(false)

  useEffect(() => {
    if (containerRef.current) {
      setIsOverflowing(
        containerRef?.current?.scrollWidth > containerRef?.current?.clientWidth,
      )
    }
  }, [selectedBoxData])

  const onSearch = () => {
    return
  }

  const filteredItems =
    clickedItem &&
    clickedItem.filter((item: any) =>
      item.gameName.toLowerCase().includes(search.toLowerCase()),
    )
  return (
    <>
      {isActualFuture9() && currentDomain.type === 'b2c' && (
        <Helmet>
          <meta name='description' content={getTitleForContent().description} />
          <meta
            name='keywords'
            content='Online Casino India, casino, live casino Games, Live Casino Games in India, live casino Online'
          />
        </Helmet>
      )}

      <div className='relative md:col-span-12 px-4 mt-4 xl:ml-4 w-full'>
        <div className={`absolute`}></div>

        <div className='flex justify-between'>
          <div>
            <div
              style={{ textTransform: 'uppercase' }}
              className='sm:mb-2 mb-0 gradient_text md:text-5xl text-[24px] font-bebas uppercase font-bold tracking-normal text-left'
            >
              {getTitleForContent().translatedName}
            </div>
            <div className='mb-3 text-[14px] font-normal tracking-wider text-black dark:text-white font-bebas md:font-bold md:text-3xl '>
              {getTitleForContent().translatedDescription}
            </div>
          </div>
          <div className='text-[#fff] relative mr-[5px] md:mr-[32px]'>
            <SearchableSearchBox
              placeholder='Search game'
              search={search}
              setSearchQuery={setSearch}
              className={`w-full xs:h-[40px] sm:h-[50px] rounded-full xs:px-2 sm:px-8 py-4   dark:from-[var(--gamePlay-secondColor)]  dark:to-[var(--gamePlay-firstColor)] dark:border-solid dark:border-[2px] border dark:border-[var(--gamePlay-secondColor)] border-[var(--gamePlay-firstColor)] dark:bg-transparent bg-white dark:placeholder:!text-white placeholder:!text-black dark:shadow-none shadow-[0px_4px_44px_0px_#0000001f] text-black dark:text-white focus:outline-none
                        font-primary xs:text-[12px] sm:text-base font-normal leading-[30px] tracking-normal text-left`}
              suggestionList={[]}
              isImageDisabled={true}
              searchQuerySubit={onSearch}
            />
            <IoSearch className='dark:text-white text-black absolute right-[20px] xs:top-[8px] sm:top-[13.5px] xs:h-[18px] sm:w-[22px] xs:h-[18px] sm:h-[22px]' />
          </div>
        </div>

        {/* --------------------------Boxes--------------------------------------- */}

        {casinoDataForPage && casinoDataForPage?.length === 0 ? (
          <div className='w-full justify-center'>
            <CircularProgress style={{ color: 'white', fontSize: '25px' }} />
          </div>
        ) : (
          <Box
            className={` flex-wrap justify-between p-2 grid xs:grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-9 gap-3`}
            sx={{
              overflowY: isSmallScreen ? 'auto' : 'unset',
              maxHeight: isSmallScreen ? '300px' : 'unset',
              overflowX: 'hidden',
              '&::-webkit-scrollbar': {
                borderRadius: '10px',
                border: 'solid',
                height: '10px',
                width: '5px',
              },
              '&::-webkit-scrollbar-thumb': {
                cursor: 'pointer',
                backgroundColor: 'var(--selected-theme-color2)',
                borderRadius: '10px',
                border: 'solid',
              },
            }}
          >
            {!!casinoDataForPage &&
              !!casinoDataForPage.length &&
              casinoDataForPage.map((data: any, index: number) => {
                return currentDomain.domainName !== 'test.future9.club' &&
                  currentDomain.domainName !== 'lazer7.com' &&
                  EUROPIAN_GAME.has(data.subProviderName) ? (
                  <></>
                ) : (
                  <div key={index}>
                    <div
                      style={{ overflow: 'hidden' }}
                      className={`flex flex-col justify-center items-center border border-solid dark:border-white border-black rounded p-4 cursor-pointer hover:scale-105 transition-transform duration-300 ${selectedBox === data.subProviderName ? 'bg-[linear-gradient(91deg,_var(--selected-theme-color)_3.38%,_var(--selected-theme-color2)_109.87%)]' : ''}`}
                      onClick={() =>
                        handleBoxClick(data.subProviderName, data.subProviders)
                      }
                    >
                      <p
                        className={`text-sm font-bold ${selectedBox === data.subProviderName ? 'text-white' : 'dark:text-white'} text-black whitespace-nowrap`}
                      >
                        {data.subProviderName}
                      </p>
                    </div>
                  </div>
                )
              })}
          </Box>
        )}

        {/* -----------------------------Slider Games-------------------------------- */}
        {!!selectedBoxData && !!selectedBoxData.length && (
          <Box
            className='border border-solid dark:border-[--theme-color] rounded-md border-black mt-8 h-auto relative'
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
              paddingBottom: !isOverflowing ? '10px' : '0px',

              overflowX: 'auto',
              overflowY: 'hidden',
              '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
                visibility: isHovered ? 'visible' : 'hidden',
              },
              '&::-webkit-scrollbar-track': {
                background: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                borderRadius: '4px',
                backgroundClip: 'padding-box',
              },
              '&:hover': {
                overflowY: 'auto',
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'var(--selected-theme-color)',
                },
              },
              transition: 'box-shadow 0.3s ease-in-out',
            }}
          >
            <div
              className='flex space-x-4 pt-3 px-2'
              style={{ scrollBehavior: 'smooth' }}
              ref={containerRef}
            >
              {selectedBoxData &&
                selectedBoxData.length > 0 &&
                selectedBoxData.map((res, index) =>
                  isAnyEuropianGame(res.games, currentDomain) ? (
                    <></>
                  ) : (
                    <div key={index} className='flex-shrink-0'>
                      <div
                        className={`text-black font-medium flex flex-col justify-center items-center p-3 rounded-md shadow-md transition-transform transform hover:scale-105 duration-300 ${
                          clickedItem === res.games
                            ? 'border-2 border-[--theme-color] bg-[linear-gradient(91deg,_var(--selected-theme-color)_3.38%,_var(--selected-theme-color2)_109.87%)] text-white'
                            : 'bg-transparent border border-1 dark:border-white border-black '
                        } cursor-pointer`}
                        onClick={() => handleItemClick(res.games, res.gameType)}
                      >
                        <p
                          className={`text-[13px] ${clickedItem === res.games ? 'text-white' : 'text-black  dark:text-white'} whitespace-nowrap`}
                        >
                          {res.gameType}
                        </p>
                      </div>
                    </div>
                  ),
                )}
              {/* Add padding if no overflow */}
            </div>
          </Box>
        )}

        {/* --------------------------Casino Tiles---------------------------------------- */}

        <Box
          className='mt-2 w-full'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={{
            maxHeight: '1000px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              borderRadius: '10px',
              width: '7px',
              visibility: isHovered ? 'visible' : 'hidden',
            },
            '&::-webkit-scrollbar-thumb': {
              cursor: 'pointer',
              backgroundColor: isHovered
                ? 'var(--selected-theme-color2)'
                : ' transparent',
              borderRadius: '10px',
            },
            '@media screen and (min-width: 1200px)': {
              '&::-webkit-scrollbar': {
                height: '10px',
                width: isLargeScreen ? '5px' : '0',
                visibility: isHovered ? 'visible' : 'hidden',
              },
            },
          }}
        >
          <div
            style={{ scrollBehavior: 'smooth' }}
            className='flex flex-wrap mt-3 mb-3  justify-center md:justify-start '
          >
            {!!selectedBox &&
              selectedBox === 'Ezugi' &&
              (!gameType || gameType === 'All') &&
              EZUGI_DEFAULT_PRORITY.map((data: any, index: number) =>
                currentDomain.domainName !== 'test.future9.club' &&
                currentDomain.domainName !== 'lazer7.com' &&
                EUROPIAN_GAME.has(data.subProviderName) ? (
                  <></>
                ) : (
                  <div
                    key={index}
                    className={` ${isSmallestSmallScreen ? '!w-[50%] ' : ''} flex flex-wrap  w-[30%] h-[100px] sm:w-[25%] sm:h-[110px] md:w-[19.9%] md:h-[160px] xl:h-[210px] 2xl:h-[300px] justify-center `}
                  >
                    <div
                      className='w-full h-full'
                      onClick={() =>
                        gameClick(
                          data.gameId,
                          data.gameName,
                          data.providerName,
                          data.subProviderName,
                          data.gameCode,
                        )
                      }
                    >
                      <CasinoTile
                        isCasinoPage={true}
                        isPageVisible={true}
                        toggleAll={emptyFunction}
                        isDarkMode={props.isDarkMode}
                        casinoData={data}
                        liveCasino={true}
                      />
                    </div>
                  </div>
                ),
              )}

            {!!selectedBox &&
              selectedBox === 'Aviator' &&
              (!gameType || gameType === 'All') &&
              AVIATOR_DEFAULT_PRORITY.map((data: any, index: number) =>
                currentDomain.domainName !== 'test.future9.club' &&
                currentDomain.domainName !== 'lazer7.com' &&
                EUROPIAN_GAME.has(data.subProviderName) ? (
                  <></>
                ) : (
                  <div
                    key={index}
                    className={` ${isSmallestSmallScreen ? '!w-[50%] ' : ''} flex flex-wrap  w-[30%] h-[100px] sm:w-[25%] sm:h-[110px] md:w-[19.9%] md:h-[160px] xl:h-[210px] 2xl:h-[300px] justify-center `}
                  >
                    <div
                      className='w-full h-full'
                      onClick={() =>
                        gameClick(
                          data.gameId,
                          data.gameName,
                          data.providerName,
                          data.subProviderName,
                          data.gameCode,
                        )
                      }
                    >
                      <CasinoTile
                        isCasinoPage={true}
                        isPageVisible={true}
                        toggleAll={emptyFunction}
                        isDarkMode={props.isDarkMode}
                        casinoData={data}
                        liveCasino={true}
                      />
                    </div>
                  </div>
                ),
              )}

            {!!filteredItems &&
              filteredItems.map((data: any, index: number) =>
                currentDomain.domainName !== 'test.future9.club' &&
                currentDomain.domainName !== 'lazer7.com' &&
                EUROPIAN_GAME.has(data.subProviderName) ? (
                  <></>
                ) : !!selectedBox &&
                  (selectedBox === 'Ezugi' || selectedBox === 'Aviator') &&
                  (!gameType || gameType === 'All') ? (
                  EZUGI_REMOVE_GAME.has(data.gameId) ||
                  AVIATOR_REMOVE_GAME.has(data.gameId) ? (
                    <></>
                  ) : (
                    <div
                      key={index}
                      className={` ${isSmallestSmallScreen ? '!w-[50%] ' : ''} flex flex-wrap  w-[30%] h-[100px] sm:w-[25%] sm:h-[110px] md:w-[19.9%] md:h-[160px] xl:h-[210px] 2xl:h-[300px] justify-center `}
                    >
                      <div
                        className='w-full h-full'
                        onClick={() =>
                          gameClick(
                            data.gameId,
                            data.gameName,
                            data.providerName,
                            data.subProviderName,
                            data.gameCode,
                          )
                        }
                      >
                        <CasinoTile
                          isCasinoPage={true}
                          isPageVisible={true}
                          toggleAll={emptyFunction}
                          isDarkMode={props.isDarkMode}
                          casinoData={data}
                          liveCasino={true}
                        />
                      </div>
                    </div>
                  )
                ) : (
                  <div
                    key={index}
                    className={` ${isSmallestSmallScreen ? '!w-[50%] ' : ''} flex flex-wrap  w-[30%] h-[100px] sm:w-[25%] sm:h-[110px] md:w-[19.9%] md:h-[160px] xl:h-[210px] 2xl:h-[300px] justify-center `}
                  >
                    <div
                      className='h-full w-full'
                      onClick={() =>
                        gameClick(
                          data.gameId,
                          data.gameName,
                          data.providerName,
                          data.subProviderName,
                          data.gameCode,
                        )
                      }
                    >
                      <CasinoTile
                        isCasinoPage={true}
                        isPageVisible={true}
                        toggleAll={emptyFunction}
                        isDarkMode={props.isDarkMode}
                        casinoData={data}
                        liveCasino={true}
                      />
                    </div>
                  </div>
                ),
              )}
          </div>
        </Box>

        <>
          <div className='text-black dark:text-white mb-1 mt-2 '>
            {' '}
            <span className='bold ml-5 text-[16px]'>
              {' '}
              {_getLocalString('LOC_SUGGESTION_FROM_AI')} :{' '}
            </span>
            {_getLocalString('LOC_SUGGESTION_FROM_AI_CONTENT')}
          </div>

          <Box
            style={{ placeContent: 'center' }}
            className='mb-45 flex-wrap p-2 cursor-pointer flex gap-2  rounded-md border-black mt-2'
          >
            {getSuggestionImages().map((data, index) => {
              return (
                <div
                  key={index}
                  className={` ${isSmallestSmallScreen ? '!w-[47%] ' : ''}   w-[23%] sm:w-[20%]  md:w-[10.66%] `}
                >
                  <div
                    onClick={() =>
                      gameClick(
                        data.gameId,
                        data.gameName,
                        data.providerName,
                        data.subProviderName,
                        data.gameCode,
                      )
                    }
                  >
                    <img
                      alt='Rounded Image'
                      src={getAssetUrl(data.image)}
                      className={`rounded-lg w-full h-full`}
                    />
                  </div>
                </div>
              )
            })}
            {pathname === '/live-casino' &&
              DEFAULT_EVOLUTION_TABLE_ENTRY.map((game) => {
                return (
                  <div
                    className={` ${isSmallestSmallScreen ? '!w-[47%] ' : ''}   w-[25%] sm:w-[20%]  md:w-[10.66%] `}
                    onClick={() => {
                      if (!props.isLogin) {
                        return props.updateOpenLoginScreen(true)
                      }
                      evolutionGaming(game.category)
                    }}
                  >
                    <img
                      alt='Rounded Image'
                      src={game.image}
                      className={`rounded-lg w-full h-full
                        `}
                    />
                  </div>
                )
              })}
          </Box>

          <Content pathname={pathname} />
        </>
      </div>
    </>
  )
}

export default Account
